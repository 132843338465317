import * as React from "react";
import styled from "styled-components";

export interface IContainerProps {
  children: any;
  className?: string;
}

const ContainerDiv = styled.div`
  border-radius: ${props => props.theme.borderRadius};
  margin: ${props => props.theme.margin.single};
  padding: ${props => props.theme.padding.single};
  margin-top: 0px;
  border: none;
  @media (max-width: 600px) {
    padding: 5px;
    border-radius: ${props => props.theme.mobileScreen.borderRadius};
  }
`;
export default function Container(props: IContainerProps) {
  return (
    <ContainerDiv className={props.className}>{props.children}</ContainerDiv>
  );
}
