import * as React from "react";
import styled from "styled-components";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  children?: any;
}
const StyledButton = styled.button`
  background: ${props =>
    props.disabled
      ? props.theme.palette.disabled
      : props.theme.palette.foreground};
  color: ${props => props.theme.palette.text};
  border-radius: ${props => props.theme.borderRadius};
  //   margin: ${props => props.theme.margin.single};
  //   padding: ${props => props.theme.padding.double};
  text-align: center;
  font-size: ${props => props.theme.fontSizes.sm};
  cursor: pointer;
  border: none;
  line-height: ${props => props.theme.height.line};
  @media screen and (max-width: 600px) {
    padding: ${props => props.theme.padding.single};
    line-height: normal;
    border-radius: ${props => props.theme.mobileScreen.borderRadius};
  }
`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref: any) => {
    return (
      <StyledButton
        ref={ref}
        type={props.type ?? "button"}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {props.children}
      </StyledButton>
    );
  }
);
Button.displayName = "Button";
export { Button };
