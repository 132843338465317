import * as React from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";
import Select, { StylesConfig } from "react-select";

export interface DropdownProps {
  options: string[];
  placeholder?: string;
}
const SelectBox = styled(Select)`
  .Select__control {
    background: ${props => props.theme.palette.inputBackground};
    color: ${props => props.theme.palette.inputText};
    border-radius: ${props => props.theme.borderRadius};
    padding-left: ${props => props.theme.padding.single};
    line-height: ${props => props.theme.height.line};
    border: none;
    @media screen and (max-width: 600px) {
      padding: 4px;
      line-height: normal;
      border-radius: ${props => props.theme.mobileScreen.borderRadius};
      font-size: ${props => props.theme.fontSizes.xs};
    }
  }
  .Select__control > div {
    padding: 0;
  }
  .Select__menu {
    background: ${props => props.theme.palette.inputBackground};
    color: ${props => props.theme.palette.inputText};
    border-radius: ${props => props.theme.borderRadius};
    padding: 10px;
    margin-top: 1px !important;
    border: none;
    @media screen and (max-width: 600px) {
      padding: 4px;
      line-height: normal;
      border-radius: ${props => props.theme.mobileScreen.borderRadius};
      font-size: ${props => props.theme.fontSizes.xs};
    }
  }
  .Select__input-container {
    margin: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .Select__option {
    flex: 6;
    @media screen and (max-width: 600px) {
      padding: ${props => props.theme.mobileScreen.padding.single};
    }
  }
  .Select__indicator-separator {
    display: none;
  }
  &.dropdownerror .Select__control {
    outline: 1px solid ${props => props.theme.palette.errorText} !important;
    .Select__placeholder {
      color: ${props => props.theme.palette.errorText} !important;
    }
  }
  .Select__option--is-disabled {
    pointer-events: none;
    font-family: "Chapeau-light";
    color: #241f21;
  }
`;

const style = (themeVal: DefaultTheme) => {
  const colourStyles: StylesConfig = {
    option: styles => {
      return {
        ...styles,
        backgroundColor: themeVal.palette.inputBackground,
        color: themeVal.palette.inputText,
        ":hover": {
          ...styles[":active"],
          backgroundColor: themeVal.palette.background,
          color: themeVal.palette.text,
          borderRadius: themeVal.borderRadius,
        },
      };
    },
  };
  return colourStyles;
};

const Dropdown = React.forwardRef<HTMLSelectElement, DropdownProps>(
  (props: DropdownProps, ref: any) => {
    const theme = useTheme();
    const colourStyles = style(theme);
    return (
      <SelectBox
        classNamePrefix="Select"
        placeholder={props.placeholder}
        styles={colourStyles}
        ref={ref}
        {...props}
        options={props.options}
        instanceId={React.useId()}
      />
    );
  }
);
Dropdown.displayName = "Dropdown";
export { Dropdown };
