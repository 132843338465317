import React from "react";
import type { NextPage } from "next";
import { Main } from "@components/application/main";
import { ThemeProvider } from "styled-components";
import { tracksuitTheme } from "@components/tracksuit-theme";
import {
  sectors,
  levelOfInnovation,
  howPurchased,
  categoryLifeStage,
  howPriced,
  howBig,
  businessType,
  placeholder,
} from "../services/calculator";

const Home: NextPage<any> = (props: any) => {
  return (
    <ThemeProvider theme={tracksuitTheme}>
      <Main {...props} />
    </ThemeProvider>
  );
};

export async function getServerSideProps() {
  const sectorsArr = sectors;
  const levelOfInnovationArr = levelOfInnovation;
  const howPurchasedArr = howPurchased;
  const categoryLifeStageArr = categoryLifeStage;
  const howPricedArr = howPriced;
  const howBigArr = howBig;
  const businessTypeArr = businessType;

  // Pass data to the page via props
  return {
    props: {
      sectorsArr,
      levelOfInnovationArr,
      howPurchasedArr,
      categoryLifeStageArr,
      howPricedArr,
      howBigArr,
      placeholder,
      businessTypeArr,
    },
  };
}

export default Home;
