import * as React from "react";
import styled, { css } from "styled-components";
import Image from "next/image";
import progressBar from "/public/progressbar.png";
export interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  value: number;
  children?: number;
  loading?: boolean;
  setProgressValue?: any;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ hidden }) => {
    return css`
      visibility: ${hidden ? "hidden" : "visible"};
    `;
  }}
`;
export default function ProgressBar(props: ProgressBarProps) {
  const ProgressDiv = styled.div`
    min-width: 5%;
    width: ${props.value}%;
    span {
      float: right;
    }
  `;
  const ProgressDivInner = styled(ProgressDiv)`
    background-color: ${props => props.theme.palette.inputBackground};
    border-radius: ${props => props.theme.borderRadius};
    height: 20px;
  `;

  return (
    <Container>
      <ProgressDiv>
        <Image
          src={progressBar}
          width={90}
          height={90}
          alt="Tracksuit icon"
        ></Image>
      </ProgressDiv>
      <ProgressDivInner />
    </Container>
  );
}
