import React, { useEffect, useState } from "react";
import { Input } from "@components/common/input";
import { Button } from "@components/common/button";
import { Dropdown } from "@components/common/dropdown";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import Checkbox from "@components/common/checkbox";
import { useRouter } from "next/router";
import Image from "next/image";
import pointingdown from "../../public/pointingdown.png";

const defaultDropdownValues = {
  section: "",
  innovative: "",
  purchase: "",
  lifecycle: "",
  priced: "",
  size: "",
};

//@ts-ignore
const fetcher = (...args) => fetch(...args).then(res => res.json());
const Form = styled.form`
  width: 100%;
  display: grid;
  gap: ${props => props.theme.gap.medium};
  @media (max-width: 600px) {
    gap: ${props => props.theme.gap.single};
  }
`;
const Row = styled.div`
  display: flex;
  gap: ${props => props.theme.gap.double};
  align-items: stretch;
  margin: 0px ${props => props.theme.margin.single};
  select {
    flex: 6;
  }
  > div {
    flex: 6;
  }
  button {
    flex: 2;
  }
  .buttondiv {
    flex: 6;
    display: flex;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    gap: ${props => props.theme.gap.single};
    button {
      display: none;
    }
    :last-child {
      flex-direction: row;
      button {
        display: block;
      }
    }
  }
`;
const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
`;
const Span = styled.span`
  margin-left: 8px;
`;
const Info = styled.div`
  & span {
    top: 6px;
  }
  @media screen and (max-width: 600px) {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`;
const Label = styled.label``;
export function Calculator(props: any) {
  const ref = React.createRef() as any;
  const [shouldFetch, setShouldFetch] = useState(false);
  const router = useRouter();
  const originUrl = router.query.currentUrl ?? "https://www.gotracksuit.com/";
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const originUrlWithParam = new URL(originUrl as string);
  const utmCampaign = originUrlWithParam.searchParams.get("utm_campaign");
  const utmMedium = originUrlWithParam.searchParams.get("utm_medium");
  const utmSource = originUrlWithParam.searchParams.get("utm_source");
  const utmTerm = originUrlWithParam.searchParams.get("utm_term");

  React.useEffect(() => {
    const localStorageData = localStorage.getItem("userinfo");
    if (localStorageData) {
      const userData = JSON.parse(localStorageData);
      setValue("name_first", userData.firstName);
      setValue("name_last", userData.lastName);
      setValue("email", userData.email);
      setValue("role_title", userData.role);
      setValue("company", userData.company);
      setValue("business_type", userData.businessType);
    }
  }, []);
  const serverBaseUrl =
    `${process.env.NEXT_PUBLIC_CALCULATOR_URL}` || "http://localhost:3000";
  const calculateSpendURI = `${serverBaseUrl}/api/calculate-spend`;

  const onSubmit = async (formData: any) => {
    if (typeof window !== "undefined") {
      if (window.location !== window.parent.location) {
        window.parent.postMessage("Scrollup", "*");
      }
    }
    setShouldFetch(true);
    props.setLoading(true);
    props.setResults(undefined);
    const requestBody = {
      ...formData,
      originUrl: originUrl,
      howPriced:
        formData.howPriced === "Value" || formData.howPriced === "Mainstream"
          ? "Value / Mainstream"
          : formData.howPriced,
      ...(utmCampaign && {
        utm_campaign: utmCampaign,
      }),
      ...(utmMedium && {
        utm_medium: utmMedium,
      }),
      ...(utmSource && {
        utm_source: utmSource,
      }),
      ...(utmTerm && {
        utm_term: utmTerm,
      }),
    };
    const response = await fetch(calculateSpendURI, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
        Cookie: props.cookie,
      },
    });
    const result = await response.json();
    localStorage.setItem("userinfo", JSON.stringify(result));
    // props.setResults(result); // going to show results on results page.
    setTimeout(() => {
      // This is for webflow parent page manangement
      if (typeof window !== "undefined") {
        if (window.location !== window.parent.location) {
          window.parent.postMessage("redirect@" + result.resultUuid, "*");
        } else {
          router.push(`/results/${result.resultUuid}`);
        }
      }
      // props.setLoading(false);
    }, 1500);
  };
  return (
    <Fieldset disabled={props.loading}>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Row>
          <Input
            type="text"
            placeholder="First Name*"
            className={errors.name_first ? "error" : ""}
            {...register("name_first", { required: true })}
          />
          <Input
            type="text"
            placeholder="Last Name*"
            className={errors.name_last ? "error" : ""}
            {...register("name_last", { required: true })}
          />
        </Row>
        <Row>
          <Input
            type="text"
            placeholder="Work Email*"
            className={errors.email ? "error" : ""}
            {...register("email", {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
          />
          <Input
            type="text"
            placeholder="Company*"
            className={errors.company ? "error" : ""}
            {...register("company", { required: true })}
          />
        </Row>
        <Row>
          <div>
            <Input
              type="text"
              placeholder="Role Title*"
              className={errors.role_title ? "error" : ""}
              {...register("role_title", { required: true })}
            />
          </div>
          <Controller
            name={"business_type"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <Dropdown
                  {...field}
                  placeholder={props.placeholder.dropDown.type}
                  options={props.businessTypeArr}
                  //@ts-ignore
                  value={props.businessTypeArr.find(
                    (c: any) => c.value === field.value
                  )}
                  onChange={(val: any) => field.onChange(val.value)}
                  className={fieldState.error ? "dropdownerror" : ""}
                />
              );
            }}
          />
        </Row>
        <Row>
          <Info>
            <Image src={pointingdown} alt="Point Icon" width={20} height={20} />
            &nbsp;Choose the option that’s most suitable for each field
          </Info>
        </Row>
        <Row>
          <Controller
            name={"sector"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <Dropdown
                  {...field}
                  placeholder={props.placeholder.dropDown.sectors}
                  options={props.sectorsArr}
                  //@ts-ignore
                  value={props.sectorsArr.filter(
                    (c: any) => c.value === field.value
                  )}
                  onChange={(val: any) => field.onChange(val.value)}
                  className={fieldState.error ? "dropdownerror" : ""}
                />
              );
            }}
          />
          <Button ref={ref} disabled={true}></Button>
        </Row>
        <Row>
          <Controller
            name={"innovative"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <Dropdown
                  {...field}
                  placeholder={props.placeholder.dropDown.innovation}
                  options={props.levelOfInnovationArr}
                  //@ts-ignore
                  value={props.levelOfInnovationArr.filter(
                    (c: any) => c.value === field.value
                  )}
                  onChange={(val: any) => field.onChange(val.value)}
                  className={fieldState.error ? "dropdownerror" : ""}
                />
              );
            }}
          />
          <Button ref={ref} disabled={true}></Button>
        </Row>
        <Row>
          <Controller
            name={"purchase"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <Dropdown
                  {...field}
                  placeholder={props.placeholder.dropDown.purchased}
                  options={props.howPurchasedArr}
                  //@ts-ignore
                  value={props.howPurchasedArr.filter(
                    (c: any) => c.value === field.value
                  )}
                  onChange={(val: any) => field.onChange(val.value)}
                  className={fieldState.error ? "dropdownerror" : ""}
                />
              );
            }}
          />
          <Button ref={ref} disabled={true}></Button>
        </Row>
        <Row>
          <Controller
            name={"lifecycle"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <Dropdown
                  {...field}
                  placeholder={props.placeholder.dropDown.lifecycle}
                  options={props.categoryLifeStageArr}
                  //@ts-ignore
                  value={props.categoryLifeStageArr.filter(
                    (c: any) => c.value === field.value
                  )}
                  onChange={(val: any) => field.onChange(val.value)}
                  className={fieldState.error ? "dropdownerror" : ""}
                />
              );
            }}
          />
          <Button ref={ref} disabled={true}></Button>
        </Row>
        <Row>
          <Controller
            name={"priced"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <Dropdown
                  {...field}
                  placeholder={props.placeholder.dropDown.priced}
                  options={props.howPricedArr}
                  //@ts-ignore
                  value={props.howPricedArr.filter(
                    (c: any) => c.value === field.value
                  )}
                  onChange={(val: any) => field.onChange(val.value)}
                  className={fieldState.error ? "dropdownerror" : ""}
                />
              );
            }}
          />
          <Button ref={ref} disabled={true}></Button>
        </Row>
        <Row>
          <Controller
            name={"size"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <Dropdown
                  {...field}
                  placeholder={props.placeholder.dropDown.size}
                  options={props.howBigArr}
                  //@ts-ignore
                  value={props.howBigArr.filter(
                    (c: any) => c.value === field.value
                  )}
                  onChange={(val: any) => field.onChange(val.value)}
                  className={fieldState.error ? "dropdownerror" : ""}
                />
              );
            }}
          />
          <Button ref={ref} disabled={true}></Button>
        </Row>
        <Row>
          <Label>
            <Controller
              name={"news_letter"}
              control={control}
              defaultValue={true}
              render={({ field }) => {
                return (
                  <Checkbox
                    checked={field.value}
                    onChange={e => {
                      field.onChange(!field.value);
                    }}
                  />
                );
              }}
            />
            <Span>Subscribe to Tracksuit&apos;s newsletter</Span>
          </Label>
        </Row>
        <Row>
          <div className="buttondiv">
            <Button ref={ref} type="submit">
              Calculate
            </Button>
          </div>
          {/* <DownloadButton ref={ref}></DownloadButton> */}
          <Button ref={ref} onClick={() => reset({ defaultDropdownValues })}>
            Clear
          </Button>
        </Row>
      </Form>
    </Fieldset>
  );
}
