import * as React from "react";
import styled from "styled-components";

const FooterDiv = styled.div`
  text-align: center;
  color: ${props => props.theme.palette.inputBackground};
  @media (max-width: 600px) {
    padding: 20px;
  }
`;
export default function Footer(props: any) {
  return <FooterDiv>Credit to Peter Field and Les Binet</FooterDiv>;
}
