import { DefaultTheme } from "styled-components";

export const tracksuitTheme: DefaultTheme = {
  borderRadius: "15px",
  fontFamily: "Chapeau",
  fontSizes: {
    xl: "2em",
    l: "1.5em",
    m: "1.25em",
    sm: "1em",
    xs: "0.8em",
  },
  padding: {
    single: "10px",
    double: "20px 10px",
  },
  margin: {
    single: "10px",
    double: "12px 10px",
  },
  height: {
    line: "60px",
  },
  gap: {
    single: "10px",
    medium: "14px",
    double: "20px",
  },
  palette: {
    background: "#5e507a",
    foreground: "#8E81C6",
    text: "#FDFBF6",
    inputText: "#62557E",
    inputBackground: "#F3F0EC",
    errorText: "#f88379",
    disabled: "#6D6088",
  },
  mobileScreen: {
    borderRadius: "10px",
    padding: {
      single: "5px",
    },
  },
};
