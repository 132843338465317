import * as React from "react";
import styled from "styled-components";
import Head from "next/head";
import Image from "next/image";
import { Banner } from "@components/application/banner";
import { Calculator } from "@components/application/calculator";
import TracksuitIcon from "public/icon.png";
import Container from "@components/common/container";
import Footer from "@components/common/footer";

const LeftDiv = styled.div`
  text-align: left;
  padding-left: 10px;
  @media (max-width: 600px) {
    img {
      max-height: 40px;
    }
  }
`;
const ContainerDiv = styled(Container)`
  background: ${props => props.theme.palette.background};
  color: ${props => props.theme.palette.text};
  min-height: 1090px;
  display: grid;
  gap: ${props => props.theme.gap.single};
  @media (max-width: 600px) {
    display: block;
    min-height: 940px;
  }
`;
let interval: any = undefined;
const defaultPersonalValues = {
  name_first: "",
  name_last: "",
  email: "",
  company: "",
  role_title: "",
  business_type: "",
};

export function Main(props: any) {
  const [loading, setLoading] = React.useState(false);
  const [progressValue, setProgressValue] = React.useState(0);
  const [results, setResults] = React.useState(undefined);

  React.useEffect(() => {
    if (loading) {
      interval = setInterval(() => {
        setProgressValue(prev => prev + 1);
      }, 50);
    } else {
      clearInterval(interval);
    }
  }, [loading]);

  React.useEffect(() => {
    if (progressValue === 100) {
      // comment out as we're redirecting to results page.
      // setLoading(false);
      clearInterval(interval);
      // setProgressValue(0);
    }
  }, [progressValue]);

  return (
    <ContainerDiv>
      <Head>
        <title>Tracksuit: Brand budget calculator</title>
        <link rel="shortcut icon" href="/favicon.png" />
        <meta name="robots" content="noindex" />
      </Head>
      <LeftDiv>
        <Image
          src={TracksuitIcon}
          width={27}
          height={56}
          alt="Tracksuit icon"
        ></Image>
      </LeftDiv>
      <Banner
        loading={loading}
        setLoading={setLoading}
        progressValue={progressValue}
        results={results}
      />
      <Calculator
        {...props}
        loading={loading}
        setLoading={setLoading}
        setResults={setResults}
      />
      <Footer />
    </ContainerDiv>
  );
}
