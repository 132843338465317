import Container from "@components/common/container";
import * as React from "react";
import styled, { css } from "styled-components";
import ProgressBar from "@components/common/progress-bar";
export interface BannerProps {
  className?: string;
  role?: string;
  children?: any;
  brand: number;
  activiation: number;
}
export const BannerDiv = styled(Container)`
  background: ${props => props.theme.palette.foreground};
  color: ${props => props.theme.palette.text};
`;

const WelcomeH4 = styled.h4`
  text-align: center;
  font-size: ${props => props.theme.fontSizes.l};
  @media screen and (max-width: 600px) {
    font-size: ${props => props.theme.fontSizes.sm};
  }
`;
export function Banner(props: any) {
  return (
    <BannerDiv>
      {props.results === undefined && props.loading === false && (
        <div>
          <WelcomeH4>Calculate your marketing budget split</WelcomeH4>
        </div>
      )}
      {props.results && props.loading === false && (
        <Results
          brand={props.results?.brandWeight}
          activiation={props.results?.activationWeight}
        />
      )}
      {props.loading && (
        <ProgressBar value={props.progressValue} loading={props.loading} />
      )}
    </BannerDiv>
  );
}

// Result ST
export interface ResultsProps {
  brand: number;
  activiation: number;
  loading?: boolean;
}
const H4 = styled.h4`
  font-size: ${props => props.theme.fontSizes.m};
`;
const Div = styled.div`
  display: flex;
  align-items: stretch;
`;
const ResultDiv = styled.div`
  flex: 1;
`;
const ResultSpan = styled.span`
  font-size: ${props => props.theme.fontSizes.xl};
`;
const MainDiv = styled.div`
  ${({ hidden }) => {
    return css`
      visibility: ${hidden ? "hidden" : "visible"};
    `;
  }}
`;

export function Results(props: ResultsProps) {
  return (
    <MainDiv>
      <H4>Your optimal budget split to maximise growth…</H4>
      <Div>
        <ResultDiv>
          <ResultSpan>{props.brand}%</ResultSpan> long-term brand building
        </ResultDiv>
        <ResultDiv>
          <ResultSpan>{props.activiation}%</ResultSpan> short-term sales
          activation
        </ResultDiv>
      </Div>
    </MainDiv>
  );
}
