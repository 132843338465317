import * as React from "react";
import styled from "styled-components";
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const StyledInput = styled.input`
  background: ${props => props.theme.palette.inputBackground};
  color: ${props => props.theme.palette.inputText};
  border-radius: ${props => props.theme.borderRadius};
  padding-left: ${props => props.theme.padding.single};
  font-size: ${props => props.theme.fontSizes.sm};
  line-height: ${props => props.theme.height.line};
  border: none;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding: ${props => props.theme.padding.single};
    line-height: normal;
    border-radius: ${props => props.theme.mobileScreen.borderRadius};
    font-size: ${props => props.theme.fontSizes.xs};
  }
  &.error {
    outline: 1px solid ${props => props.theme.palette.errorText};
    color: ${props => props.theme.palette.errorText};
    &::placeholder {
      color: ${props => props.theme.palette.errorText};
    }
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref: any) => {
    Input.displayName = "Input";
    return (
      <StyledInput
        ref={ref}
        type={props.type ?? "text"}
        name={props.name}
        placeholder={props.placeholder}
        required={props.required}
        {...props}
      />
    );
  }
);

export { Input };
